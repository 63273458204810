body {
  font-family: "Comic Sans MS", sans-serif;
  padding: 20px;
  background-color: #002366;
  color: #fff;
}

h1 {
  text-align: center;
  font-size: 36px;
  color: #ffc600;
  text-decoration: underline;
  margin-bottom: 20px;
}
h4 {
  text-align: center;
  font-size: 16px;
  color: #ffc600;
  font-style: italic;
  margin-bottom: 20px;
}

h2 {
  text-align: right;
  font-size: 24px;
  color: #ffc600;
  margin-bottom: 20px;
}

.item {
  margin: 10px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  width: 25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
}

.item h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #002366;
}

.item p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #002366;
}

.item img {
  width: 200px;
  height: 200px;
}
.item button {
  padding: 5px 10px;
  font-size: 14px;
  color: #002366;
  background-color: #ffc600;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

@keyframes explosion {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

.layout {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
}

.shopping-cart {
  text-align: right;
  position: fixed;
  right: 2rem;
  top: 0rem;
  float: right;
  padding: 0px 1rem 1rem 1rem;
margin-top: 1rem;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .item {
    width: 15rem;
  }
  .shopping-cart {
    right: 0rem;
    top: 122px;
    padding: 0px 10px 10px 10px;
    background: rgba(0, 0, 0, 0.5);
  }
}
@media only screen and (min-width: 1400px) {
  .shopping-cart {
    right: 10rem;
  }
}
